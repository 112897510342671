<template>
<div class="hello">
  
  <div v-for="prompt, i in prompts" :key="prompt.name"
       class="prompt row ak-letter"
       v-show="i == activePrompt">
    <div class="small-12 large-4 columns"
         :class="`prompt-${prompt.type} small-12 columns large-${prompt.type === 'button' || prompt.type === 'choice' ? '12' : '4'} ${prompt.promptClass || ''}`">
      <h3 class="ak-cta-indicator" v-html="prompt.label"></h3>
      
      <template v-if="prompt.type === 'button'">
        <button v-if="activePrompt < prompts.length - 1"
                @click.prevent="advance"
                class="ak-userfield-submit">
          {{ prompt.button_label || 'Next' }}
        </button>
        
        <button v-if="activePrompt === prompts.length - 1"
                @click.prevent="generate">

          <svg v-if="waiting" style="    height: 20px;    width: 60px;    vertical-align: middle;" width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff">    <circle cx="15" cy="15" r="15">        <animate attributeName="r" from="15" to="15"                 begin="0s" dur="0.8s"                 values="15;9;15" calcMode="linear"                 repeatCount="indefinite" />        <animate attributeName="fill-opacity" from="1" to="1"                 begin="0s" dur="0.8s"                 values="1;.5;1" calcMode="linear"                 repeatCount="indefinite" />    </circle>    <circle cx="60" cy="15" r="9" fill-opacity="0.3">        <animate attributeName="r" from="9" to="9"                 begin="0s" dur="0.8s"                 values="9;15;9" calcMode="linear"                 repeatCount="indefinite" />        <animate attributeName="fill-opacity" from="0.5" to="0.5"                 begin="0s" dur="0.8s"                 values=".5;1;.5" calcMode="linear"                 repeatCount="indefinite" />    </circle>    <circle cx="105" cy="15" r="15">        <animate attributeName="r" from="15" to="15"                 begin="0s" dur="0.8s"                 values="15;9;15" calcMode="linear"                 repeatCount="indefinite" />        <animate attributeName="fill-opacity" from="1" to="1"                 begin="0s" dur="0.8s"                 values="1;.5;1" calcMode="linear"                 repeatCount="indefinite" />    </circle></svg>          
          <template v-else>Let's write a letter!</template>
          
        </button>
      </template>

      <template v-else-if="prompt.type === 'choice'">
        <button @click.prevent="generate" class="ak-userfield-submit">
          <svg v-if="waiting" style="    height: 20px;    width: 60px;    vertical-align: middle;" width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff">    <circle cx="15" cy="15" r="15">        <animate attributeName="r" from="15" to="15"                 begin="0s" dur="0.8s"                 values="15;9;15" calcMode="linear"                 repeatCount="indefinite" />        <animate attributeName="fill-opacity" from="1" to="1"                 begin="0s" dur="0.8s"                 values="1;.5;1" calcMode="linear"                 repeatCount="indefinite" />    </circle>    <circle cx="60" cy="15" r="9" fill-opacity="0.3">        <animate attributeName="r" from="9" to="9"                 begin="0s" dur="0.8s"                 values="9;15;9" calcMode="linear"                 repeatCount="indefinite" />        <animate attributeName="fill-opacity" from="0.5" to="0.5"                 begin="0s" dur="0.8s"                 values=".5;1;.5" calcMode="linear"                 repeatCount="indefinite" />    </circle>    <circle cx="105" cy="15" r="15">        <animate attributeName="r" from="15" to="15"                 begin="0s" dur="0.8s"                 values="15;9;15" calcMode="linear"                 repeatCount="indefinite" />        <animate attributeName="fill-opacity" from="1" to="1"                 begin="0s" dur="0.8s"                 values="1;.5;1" calcMode="linear"                 repeatCount="indefinite" />    </circle></svg>          
          <template v-else>{{ prompt.no_button }}</template>
        </button>
        <button @click.prevent="advance" v-if="!waiting"
                class="ak-userfield-submit">
          {{ prompt.yes_button }}
        </button>
      </template>
            
    </div>
    
    <div class="small-12 large-8 columns" v-if="prompt.type !== 'button' && prompt.type !== 'choice'">
      <input :ref="prompt.name" v-if="prompt.type == 'text'" type="text" v-model="answers[prompt.name]"
             :autofocus="i === 0">
      <input :ref="prompt.name" v-else-if="prompt.type == 'number'" type="number"
             v-model="answers[prompt.name]"
             :autofocus="i === 0">      
        <textarea :ref="prompt.name" v-else-if="prompt.type == 'textarea'"
                  v-model="answers[prompt.name]"
                  :autofocus="i === 0"></textarea>

        <button v-if="activePrompt < prompts.length - 1"
                @click.prevent="advance"
                class="ak-userfield-submit">
          {{ prompt.button_label || 'Next' }}
        </button>

        <button v-if="activePrompt === prompts.length - 1"
                @click.prevent="generate"
                class="ak-userfield-submit generate-letter">
      <svg v-if="waiting" style="    height: 20px;    width: 60px;    vertical-align: middle;" width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff">    <circle cx="15" cy="15" r="15">        <animate attributeName="r" from="15" to="15"                 begin="0s" dur="0.8s"                 values="15;9;15" calcMode="linear"                 repeatCount="indefinite" />        <animate attributeName="fill-opacity" from="1" to="1"                 begin="0s" dur="0.8s"                 values="1;.5;1" calcMode="linear"                 repeatCount="indefinite" />    </circle>    <circle cx="60" cy="15" r="9" fill-opacity="0.3">        <animate attributeName="r" from="9" to="9"                 begin="0s" dur="0.8s"                 values="9;15;9" calcMode="linear"                 repeatCount="indefinite" />        <animate attributeName="fill-opacity" from="0.5" to="0.5"                 begin="0s" dur="0.8s"                 values=".5;1;.5" calcMode="linear"                 repeatCount="indefinite" />    </circle>    <circle cx="105" cy="15" r="15">        <animate attributeName="r" from="15" to="15"                 begin="0s" dur="0.8s"                 values="15;9;15" calcMode="linear"                 repeatCount="indefinite" />        <animate attributeName="fill-opacity" from="1" to="1"                 begin="0s" dur="0.8s"                 values="1;.5;1" calcMode="linear"                 repeatCount="indefinite" />    </circle></svg>
      <template v-else>Let's write a letter!</template>
        </button>
      </div>

    </div>

    <textarea v-show="false" v-if="!letters.length" readonly :value="answersJson"></textarea>
    
    <button v-if="letters.length"
            @click.prevent="generate"
            class="ak-userfield-submit"> 
      <svg v-if="waiting" style="    height: 20px;    width: 60px;    vertical-align: middle;" width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff">    <circle cx="15" cy="15" r="15">        <animate attributeName="r" from="15" to="15"                 begin="0s" dur="0.8s"                 values="15;9;15" calcMode="linear"                 repeatCount="indefinite" />        <animate attributeName="fill-opacity" from="1" to="1"                 begin="0s" dur="0.8s"                 values="1;.5;1" calcMode="linear"                 repeatCount="indefinite" />    </circle>    <circle cx="60" cy="15" r="9" fill-opacity="0.3">        <animate attributeName="r" from="9" to="9"                 begin="0s" dur="0.8s"                 values="9;15;9" calcMode="linear"                 repeatCount="indefinite" />        <animate attributeName="fill-opacity" from="0.5" to="0.5"                 begin="0s" dur="0.8s"                 values=".5;1;.5" calcMode="linear"                 repeatCount="indefinite" />    </circle>    <circle cx="105" cy="15" r="15">        <animate attributeName="r" from="15" to="15"                 begin="0s" dur="0.8s"                 values="15;9;15" calcMode="linear"                 repeatCount="indefinite" />        <animate attributeName="fill-opacity" from="1" to="1"                 begin="0s" dur="0.8s"                 values="1;.5;1" calcMode="linear"                 repeatCount="indefinite" />    </circle></svg>
      <template v-else>Want to try a different letter?</template>
   </button>

    <div class="row" v-if="letters.length && !waiting">
      <div class="columns small-12" :class="letters.length === 1 ? 'large-12' : 'large-6'"
           v-for="letter, i in letters" :key="i">
        <pre v-html="letter.prefix + letter.text + letter.suffix"></pre>
        <button class="ak-userfield-submit mt-0" @click.prevent="choose(letter)">
          Choose, edit, &amp; submit
        </button>
      </div>
    </div>

    <div class="row" v-if="letters.length && !waiting">
      <div class="columns small-12">
        <button class="ak-userfield-submit mt-0" @click.prevent="tonize(null, null, 'very formal')">
          Make it more formal
        </button>
        <button class="ak-userfield-submit mt-0" @click.prevent="tonize(null, null, 'very casual')">
          Make it more casual
        </button>
        <button class="ak-userfield-submit mt-0" @click.prevent="tonize(null, null, 'a lot of facts')">
          Add more facts
        </button>
        <button class="ak-userfield-submit mt-0" @click.prevent="tonize(null, null, 'friendly')">
          Make it more friendly
        </button>
        <button class="ak-userfield-submit mt-0" @click.prevent="tonize(null, null, 'angry')">
          Make it more angry
        </button>
      </div>
    </div>

    
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    answersJson () {
      return JSON.stringify(this.answers);
    }
  },
  methods: {
    async tonize (letter, i, tone) {
      this.waiting = true;
      const result = await axios.post(`/api/converse`, { answers: this.answers, tone, version: window.AILetterVersion || '' });

      if (result.isError && this.numRetries < 3) {
        this.numRetries += 1;
        await this.tonize(letter, i, tone);
        return;
      } else {
        this.numRetries = 0;
      }
      
      this.letters = [
        ...this.letters,
        ...result.data.results.filter(o => o.text && o.text.trim()),
      ];
      this.waiting = false;
    },
    choose (letter) {
      if (typeof window.AILetterSelect === 'function') {
        window.AILetterSelect(letter);
      }
    },
    
    async generate () {
      this.waiting = true;

      const tones = ['formal and respectful.', 'informal, as if written by a millenial or Gen Z writer.'];
      if (this.letters.length === 1) {
        tones.reverse();
      } else if (this.letters.length > 2) {
        tones.sort(() => 0.5 - Math.random());
      }
      
      const result = await axios.post(`/api/converse`, { answers: this.answers, tone: tones[0], version: window.AILetterVersion || '' });

      if (result.isError && this.numRetries < 3) {
        this.numRetries += 1;
        await this.generate();
        return;
      } else {
        this.numRetries = 0;
      }
      
      this.letters = [
        ...this.letters,
        ...result.data.results.filter(o => o.text && o.text.trim()),
      ];
      
      this.waiting = false;
      this.activePrompt = this.prompts.length;

      if (this.rotation === 0) {
        this.rotation = 180;
      } else if (this.rotation === 180) {
        this.rotation = 90;
      } else if (this.rotation === 90) {
        this.rotation = 235;
      } else if (this.rotation === 235) {
        this.rotation = 0;
      }
      
    },

    focusActiveElement () {
      let activeName = this.prompts[this.activePrompt];
      if (activeName) {
        activeName = activeName.name;
      }
      if (activeName) {
        activeName = this.$refs[activeName];
      }
      if (activeName && Array.isArray(activeName)) {
        activeName = activeName[0];
      }
      console.log(activeName);
      if (activeName) {
        this.$nextTick(() => {
          activeName.focus();
        });
      }
    },
    
    advance () {
      this.activePrompt += 1;
      this.focusActiveElement();
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.focusActiveElement();
    });
  },
  data () {
    const promptEl = document.getElementById('ai-letter-prompts');
    let prompts = null;
    if (promptEl) {
      prompts = JSON.parse(promptEl.textContent);
    }
    
    return {
      numRetries: 0,
      activePrompt: 0,
      letters: [],
      waiting: false,
      rotation: 0,
      prompts: prompts || [
        {
          name: "name",
          label: "What is your name?",
          type: "text",
        },
        {
          name: "location",
          label: "Where do you live?",
          type: "text",
        },
        {
          name: "info",
          label: `<p>The UK government is making up its mind right now on how much it will invest in the Global Fund to Fight AIDS, Tuberculosis and Malaria.</p>

<p>That means we have <b>literally days</b> to tell our new Chancellor, Jeremy Hunt, why a bold UK investment is vital. Without the UK's support, the lives of millions of people will be at risk.</p>

<p><b>Will you urge our new Chancellor, Jeremy Hunt, to step up in the fight against AIDS, tuberculosis and malaria?</b></p>`,
          type: "button",
          button_label: "Yes!",
          promptClass: 'div',
        }, 
        {
          name: "important",
          label: "Why do you think it’s important to end preventable diseases once and for all?",
          type: "textarea",
        },
        {
          name: "do_its_part",
          label: "Why do you think the UK should do its part like other countries?",
          type: "textarea",
        },
        {
          name: "experience",
          label: "Share your experience of health interventions, vaccination, or supporting such efforts",
          type: "textarea",
        },
        {
          name: "branch",
          type: "choice",
          label: "Thanks! At this point we have enough information to generate a letter for you, but if you'd like, we could personalize it further by asking you a few more questions.",
          yes_button: "Let's keep talking!",
          no_button: "I'd like to see the letter.",
        },
        {
          name: "age",
          label: "How old are you?",
          type: "number",
        },
        {
          name: "how_long",
          label: "About how long have you lived in your current location?",
          type: "text",
        },
        {
          name: "occupation",
          label: "What do you do for a living?",
          type: "text",
        }
      ],

      answers: {},
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.hello {
}

div.prompt {
  max-width: 100%;
  font-size: 1.7rem;
}

div.prompt h3 {
  font-size: 22px;
  font-weight: bold;
  text-transform: none;
}
div.prompt .prompt-button h3.ak-cta-indicator,
div.prompt .prompt-choice h3.ak-cta-indicator {
  text-align: left;
  border-bottom: none;
  margin-bottom: 1.5rem;
}

pre {
  word-break: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: left;
  max-width: 100%;
  font-family: Colfax,sans-serif;

  background: white;
  font-size: 1.8rem;
  padding: 1.5rem;
}

button,
input,
textarea {
  max-width: 100%;
  font-family: Colfax,sans-serif;
  vertical-align: middle;
  box-shadow: none;
  border-radius: 0.4rem;
  display: block;
  margin: 0 auto 1rem;
}

textarea {
  min-height: 6em !important;
}
div.prompt > :not(.prompt-button):not(.prompt-choice) button {
  width: calc(100% - 65px);
  margin-right: 65px;
}
div.prompt > :not(.prompt-button):not(.prompt-choice) button:not(.generate-letter) {
  width: 150px;
}

textarea {
  height: 4rem;
  margin-top: 1rem;
}

input[type=text],
input[type=number],
textarea {
  padding: 0.5rem;
  min-height: 1.8rem;
  width: 450px;
  color: #000;
  
  border: 1px solid #D2D2D2;
  margin-bottom: 40px;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 18%);
}
h1 {
  padding: 0.5rem 1rem;
  background-color: #000;
  color: #fff;
  font-family: "Colfax Light",sans-serif;

  width: 600px;
  max-width: 100%;
  position: absolute;
  top: 15vh;
}
.h2 {
  display: block;
  width: 450px;
  max-width: 100%;
  font-size: 1.6rem;
  line-height: 1.15;
  margin: 0 auto 0.25rem;
  font-weight: bold;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
